export default function () {
    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    const statusColor = (status) => {
        switch (status) {
            case 'open':
                return 'text-green-700'
            case 'closing_soon':
                return 'text-yellow-600'
            case 'closed':
                return 'text-red-600'
            default:
                break
        }
    }

    const scrollToElementId = (id, menuHeight = 110) => {
        var mainContainer = document.getElementById('mainContainer')
        var locationDetailHeight = document.getElementById('locationDetail').offsetHeight
        var element = document.getElementById(id)
        var elementPosition = element.offsetTop
        var offsetPosition = elementPosition + menuHeight + locationDetailHeight

        mainContainer.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        })
    }

    return {
        formatPhoneNumber,
        statusColor,
        scrollToElementId,
    }
}
