<template>
    <AppLayout theme="dark">
        <main class="isolate">
            <!-- Hero -->
            <!-- <section class="relative isolate h-screen overflow-hidden pt-14">
                <div
                    aria-hidden="true"
                    class="absolute inset-0 overflow-hidden"
                >
                    <img
                        src="../../images/new-year.jpg"
                        class="h-full w-full object-cover object-center"
                    />
                </div>
                <div
                    aria-hidden="true"
                    class="absolute inset-0 bg-black opacity-50"
                ></div>
                <div class="h-screen--menu sm:flex sm:items-center">
                    <div
                        class="relative mx-auto flex max-w-3xl flex-col items-center px-12 py-20 text-center sm:py-0 lg:px-0"
                    >
                        <div class="text-center">
                            <ApplicationLogo
                                class="mx-auto w-auto h-48 sm:h-64"
                            />
                            <p
                                class="mt-6 text-lg tracking-tight leading-8 text-gray-300 sm:text-2xl"
                            >
                                {{ $t("home.heading") }}
                            </p>
                            <p
                                class="mt-10 text-2xl tracking-tight leading-8 text-gray-300 sm:text-4xl"
                            >
                                {{ $t("home.holiday") }}
                            </p>
                            <div
                                class="mt-10 flex items-center justify-center gap-x-6"
                            >
                                <Link
                                    :href="route('menu')"
                                    class="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
                                >
                                    {{ $t("home.view_menu") }}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->

            <section class="relative isolate overflow-hidden pt-14 h-screen">
                <img
                    src="https://images.unsplash.com/photo-1607301406259-dfb186e15de8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2111&q=80"
                    alt=""
                    class="absolute inset-0 -z-10 h-full w-full object-cover"
                />
                <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div class="text-center">
                        <ApplicationLogo class="mx-auto w-auto h-48 sm:h-64" />
                        <p
                            class="mt-6 text-lg tracking-tight leading-8 text-gray-300 sm:text-2xl"
                        >
                            {{ $t("home.heading") }}
                        </p>
                        <div
                            class="mt-10 flex items-center justify-center gap-x-6"
                        >
                            <Link
                                :href="route('menu')"
                                class="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
                            >
                                {{ $t("home.view_menu") }}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Content section -->
            <div class="my-32 overflow-hidden sm:my-40">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                    <div
                        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8"
                    >
                        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                            <h2
                                class="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                            >
                                {{ $t("app.navigation.menu") }}
                            </h2>
                            <p class="my-6 text-base leading-7 text-gray-600">
                                {{ menuDescription }}
                            </p>

                            <Link
                                :href="route('menu')"
                                class="font-semibold text-primary-600 hover:text-primary-500"
                            >
                                {{ $t("home.view_menu") }}
                                <span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                        <div
                            class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents"
                        >
                            <div
                                class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end"
                            >
                                <img
                                    src="https://images.unsplash.com/photo-1628676825875-031ad212c31e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                    alt=""
                                    class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                            <div
                                class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8"
                            >
                                <div
                                    class="order-first flex w-64 flex-none justify-end self-end lg:w-auto"
                                >
                                    <img
                                        src="https://images.unsplash.com/photo-1617196034564-65baf56380ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2108&q=80"
                                        alt=""
                                        class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                                <div
                                    class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none"
                                >
                                    <img
                                        src="https://images.unsplash.com/photo-1611762687807-7cdd09aef422?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
                                        alt=""
                                        class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                                <div
                                    class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none"
                                >
                                    <img
                                        src="https://images.unsplash.com/photo-1512132411229-c30391241dd8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                                        alt=""
                                        class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Testimonials -->
            <section class="pt-16 lg:py-24">
                <div class="pb-16 bg-zinc-900 lg:pb-0 lg:z-10 lg:relative">
                    <div
                        class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8"
                    >
                        <div class="relative lg:-my-8">
                            <div
                                aria-hidden="true"
                                class="absolute inset-x-0 top-0 h-1/2 lg:hidden"
                            />
                            <div
                                class="mx-auto max-w-md px-0 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full"
                            >
                                <div
                                    class="aspect-w-10 aspect-h-6 sm:rounded-xl sm:shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full"
                                >
                                    <img
                                        class="object-cover lg:h-full lg:w-full"
                                        src="https://images.unsplash.com/photo-1633613286991-611fe299c4be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                            <div
                                class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none"
                            >
                                <figure class="relative isolate pt-6 sm:pt-12">
                                    <svg
                                        viewBox="0 0 162 128"
                                        fill="none"
                                        aria-hidden="true"
                                        class="absolute top-0 left-0 -z-10 h-32 stroke-white/20"
                                    >
                                        <path
                                            id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                                            d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                                        />
                                        <use
                                            href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                                            x="86"
                                        />
                                    </svg>

                                    <Carousel
                                        :autoplay="5000"
                                        :wrap-around="true"
                                    >
                                        <Slide
                                            v-for="testimonial in testimonials"
                                            :key="testimonial"
                                            class="flex-col text-left"
                                        >
                                            <blockquote
                                                class="w-full text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9"
                                            >
                                                <p class="whitespace-pre-line">
                                                    {{ testimonial.review }}
                                                </p>
                                            </blockquote>
                                            <figcaption
                                                class="w-full mt-8 text-base font-semibold text-white"
                                            >
                                                {{ testimonial.customer_name }}
                                            </figcaption>
                                        </Slide>
                                    </Carousel>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- About Us -->
            <section id="about" class="relative isolate -z-10">
                <div class="overflow-hidden">
                    <div
                        class="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32"
                    >
                        <div
                            class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center"
                        >
                            <div
                                class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl"
                            >
                                <h1
                                    class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
                                >
                                    {{ $t("app.navigation.about") }}
                                </h1>
                                <p
                                    class="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none whitespace-pre-line"
                                >
                                    {{ location.description }}
                                </p>
                            </div>
                            <div
                                class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0"
                            >
                                <div
                                    class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80"
                                >
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1534482421-64566f976cfa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36"
                                >
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1562158147-f8d6fbcd76f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1634434738215-106d790e98b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="w-44 flex-none space-y-8 pt-32 sm:pt-0"
                                >
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1562158147-1b1bf404cba1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1635452273191-f08ce9934102?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Find Us -->
            <section id="location" class="bg-gray-100 relative">
                <main
                    class="max-w-7xl mx-auto px-3 pt-8 pb-24 sm:pt-16 sm:px-6 lg:px-8"
                >
                    <div
                        class="p-4 sm:px-6 md:grid md:grid-cols-2 md:gap-x-8 md:p-8"
                    >
                        <div class="sm:flex md:col-span-1">
                            <div
                                class="flex-shrink-0 w-full aspect-w-3 aspect-h-2 rounded-xl overflow-hidden"
                            >
                                <iframe
                                    width="600"
                                    height="450"
                                    style="border: 0"
                                    loading="lazy"
                                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJX5qvVrAQyUwR5XTMFjADIlE&&key=AIzaSyBBPEU69dYbpLBYHy1Phx58uDMyYt8_hg0"
                                ></iframe>
                            </div>
                        </div>

                        <div>
                            <div class="mt-6 sm:mt-0">
                                <h1
                                    class="text-2xl font-medium tracking-tight text-gray-900 sm:text-3xl"
                                >
                                    {{ location.name }}
                                </h1>

                                <div class="mt-3 space-y-2">
                                    <div class="flex items-center text-sm">
                                        <ClockIcon
                                            :class="[
                                                statusColor(
                                                    location.current_status
                                                        .status
                                                ),
                                                'flex-shrink-0 mr-2 h-6 w-6',
                                            ]"
                                        />
                                        <div
                                            :class="[
                                                statusColor(
                                                    location.current_status
                                                        .status
                                                ),
                                                'flex space-x-1 text-sm',
                                            ]"
                                        >
                                            <span>
                                                {{
                                                    location.current_status.text
                                                }}
                                            </span>
                                            <span
                                                aria-hidden="true"
                                                class="text-gray-900"
                                                >&middot;</span
                                            >
                                            <span>
                                                {{
                                                    location.current_status
                                                        .message
                                                }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="flex">
                                        <div
                                            class="flex items-center text-sm text-gray-700"
                                        >
                                            <MapIcon
                                                class="flex-shrink-0 mr-2 h-6 w-6 text-gray-500"
                                                aria-hidden="true"
                                            />
                                            <dd>
                                                <span class="block">
                                                    {{
                                                        location.full_address_line_1
                                                    }}
                                                </span>
                                                <span class="block">
                                                    {{
                                                        location.full_address_line_2
                                                    }}
                                                </span>
                                            </dd>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <a
                                            :href="`tel: ${location.phone_number}`"
                                            class="flex items-center text-sm"
                                        >
                                            <PhoneIcon
                                                class="flex-shrink-0 mr-2 h-6 w-6 text-gray-500"
                                                aria-hidden="true"
                                            />
                                            <p>
                                                {{
                                                    formatPhoneNumber(
                                                        location.phone_number
                                                    )
                                                }}
                                            </p>
                                        </a>
                                    </div>
                                </div>

                                <div v-if="showOrderButton" class="mt-5">
                                    <Link
                                        :href="
                                            route('order.menu', location.slug)
                                        "
                                        class="btn btn-secondary"
                                        >Online Order</Link
                                    >
                                </div>

                                <div class="mt-6">
                                    <h3
                                        class="text-lg font-medium text-gray-900"
                                    >
                                        {{ $t("location.business_hours") }}
                                    </h3>

                                    <ul class="text-sm text-gray-700 space-y-1">
                                        <li
                                            v-for="business_hour in location.business_hours"
                                            :key="business_hour.day"
                                            :class="[
                                                business_hour.is_today
                                                    ? 'font-bold'
                                                    : '',
                                                'flex justify-between',
                                            ]"
                                        >
                                            <span>{{ business_hour.day }}</span>
                                            <template
                                                v-if="
                                                    business_hour.hours.length >
                                                    0
                                                "
                                            >
                                                <span>{{
                                                    business_hour.hours.join(
                                                        ", "
                                                    )
                                                }}</span>
                                            </template>
                                            <template v-else>
                                                <span class="text-red-500">{{
                                                    $t("calendar.closed")
                                                }}</span>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </main>

        <span class="text-green-700" />
        <span class="text-yellow-600" />
        <span class="text-red-600" />
    </AppLayout>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import AppLayout from "@/Layouts/App.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import ApplicationLogo from "&/Components/Logo/ApplicationLogo.vue";
import mixins from "@/mixins";
import { ClockIcon, MapIcon, PhoneIcon } from "@heroicons/vue/24/outline";

defineProps(["location", "testimonials", "menuDescription"]);
const { formatPhoneNumber, statusColor } = mixins();
</script>
